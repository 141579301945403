import { Client } from '@twilio/conversations'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { AppWrapper, PageContent, registerLocale, setDefaultLocale } from '@procsea/design-system'

import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import {
  useActiveBuyerSubscription,
  useCurrentMarketplace,
  useUser,
} from 'src/contexts/userContext'
import { useCompanyVerticals, useSegment } from 'src/hooks'
import useChat, { ConversationContext, createConversationClient } from 'src/hooks/useChat'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'
import { useCurrenciesQuery, useMarketplacesQuery } from 'src/queries/shared'
import { enhancedConversationSelector } from 'src/selectors/conversationSelectors'
import { IdentifyBuyerArgs, LocaleParams, UserType, UserTypeParams } from 'src/types'
import { dateLocales } from 'src/utils'
import * as hotjarService from 'src/utils/tracking/hotjar/hotjar.service'

import BuyerNavigation from './navigation/BuyerNavigation'
import { useCurrentBuyerCompany, usePusherSubscriptions } from './shared/hooks'
import useDefaultMarketplaceId from './shared/hooks/marketplaces/useDefaultMarketplaceId'

const AsyncMarketplace = lazy(
  () => import('src/components/presentationals/buyer-dashboard/marketplace/Marketplace')
)

const BuyerDashboard = () => {
  const { identify, isEnabled } = useSegment()
  const user = useUser()
  const [conversationClient, setConversationClient] = useContext(ConversationContext)
  const { locale, userType } = useParams<LocaleParams & UserTypeParams>()
  const { search } = useLocation()
  const { path } = useRouteMatch()
  const verticals = useCompanyVerticals()
  const defaultMarketplaceId = useDefaultMarketplaceId()
  const buyerSubscription = useActiveBuyerSubscription(defaultMarketplaceId)
  const buyerCompany = useCurrentBuyerCompany()
  const { data: currency } = useCurrenciesQuery({
    queryOptions: {
      select: currencies => currencies.find(current => current.code === buyerCompany?.currency),
    },
  })

  const [unreadConversationMessagesCount, setUnreadConversationMessagesCount] = useState(0)
  const { data: ecosystems = [] } = useMarketplacesQuery()
  const conversations = useSelector(
    enhancedConversationSelector(userType, ecosystems, defaultMarketplaceId)
  )
  const activeMarketplace = useCurrentMarketplace(defaultMarketplaceId)

  const marketplaceUrl = `/${locale}/${UserType.BUYER}/marketplace/${defaultMarketplaceId}/`

  useChat()
  usePusherSubscriptions()
  useOrganisationPusherSubscriptions()

  useEffect(() => {
    registerLocale(locale, dateLocales[locale])
    setDefaultLocale(locale)
    setDefaultOptions({ locale: dateLocales[locale] as any })
  }, [])

  useEffect(() => {
    if (
      user &&
      ecosystems.length > 0 &&
      buyerCompany &&
      buyerSubscription &&
      isEnabled &&
      activeMarketplace &&
      currency
    ) {
      if (user.isImpersonate) {
        hotjarService.tagImpersonatedBuyer()
      }

      identify<IdentifyBuyerArgs>(`${user.id}`, {
        activeEcosystemId: activeMarketplace.id,
        activeEcosystemName: activeMarketplace.name,
        actorType: UserType.BUYER,
        companyCategory: buyerCompany.category,
        companyCity: buyerCompany.deliveryAddressLocality,
        companyFirstOrderDate: buyerCompany.firstOrderDate,
        companyId: buyerCompany.id,
        companyName: buyerCompany.name,
        companyOrderNumber: buyerCompany.orderNumber,
        country: buyerCompany.country.name,
        createdAt: user.created,
        currencyId: currency.id,
        ecosystemIds: ecosystems.map(({ id }) => id),
        ecosystemNames: ecosystems.map(({ name }) => name),
        email: user.email,
        firstName: user.firstName,
        impersonableUsers: user.impersonableUsers,
        impersonatorIsStaff: user.impersonatorIsStaff,
        isDemo: buyerCompany.isDemoCompany,
        isImpersonate: user.isImpersonate,
        language: locale,
        lastName: user.lastName,
      })
    }
  }, [
    JSON.stringify({ activeMarketplace, user, ecosystems, buyerCompany, buyerSubscription }),
    isEnabled,
  ])

  useEffect(() => {
    if (buyerCompany?.canUseChat && !conversationClient) {
      createConversationClient().then((client: Client | null) => {
        setConversationClient(client)
      })
    }
  }, [buyerCompany?.canUseChat])

  useDeepCompareEffect(() => {
    setUnreadConversationMessagesCount(
      conversations.reduce((total, current) => total + current.unreadCount, 0)
    )
  }, [conversations])

  if (user && buyerCompany && buyerSubscription && defaultMarketplaceId && verticals.length > 0) {
    return (
      <AppWrapper>
        <BuyerNavigation
          buyerCompany={buyerCompany}
          currentMarketplaceId={defaultMarketplaceId}
          unreadChatMessagesCount={unreadConversationMessagesCount}
          user={user}
        />
        <PageContent isLegacy={!user.releaseToggleNewMenu}>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route
                path={`${path}/marketplace/:marketplaceId(${buyerCompany.marketplaceIds.join(
                  '|'
                )})`}
              >
                <AsyncMarketplace />
              </Route>

              <Route exact path="/:locale/:userType(buyer)">
                <Redirect to={{ pathname: marketplaceUrl, search }} />
              </Route>

              <Route>
                <ErrorBoundary>
                  <NotFound />
                </ErrorBoundary>
              </Route>
            </Switch>
          </Suspense>
        </PageContent>
      </AppWrapper>
    )
  }
  return null
}

export default BuyerDashboard
