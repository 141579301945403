import classNames from 'classnames'
import { compact, head } from 'lodash'
import React, { useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { useBreakpoints } from '@procsea/design-system'

import SideBar, { UserInfo } from 'src/components/base/design-system/SideBar/SideBar'
import { NavItem } from 'src/components/base/design-system/SideBar/SideNavItem/SideNavItem'
import TabBar from 'src/components/base/design-system/TabBar/TabBar'
import BuyerAccountMenuItem from 'src/components/presentationals/buyer-dashboard/navigation/menu-items/account/BuyerAccountMenuItem'
import 'src/components/presentationals/buyer-dashboard/shared/hooks'
import { useCurrentBuyerCompany } from 'src/components/presentationals/buyer-dashboard/shared/hooks'
import HelpMenu from 'src/components/presentationals/navigation/HelpMenu'
import { AppEnvironment } from 'src/components/presentationals/shared/navigation/'
import { useCurrentMarketplace } from 'src/contexts/userContext'
import { useOptionFlags } from 'src/hooks'
import { usePaginatedDashboardsQuery } from 'src/queries/buyer'
import { BuyerCompanyAsBuyer, User } from 'src/types'

import MainMenu from '../../navigation/MainMenu/MainMenu'
import './BuyerNavigation.css'
import BuyerMobileHeader from './mobile-header/BuyerMobileHeader'

const StyledSideBar = styled(SideBar)`
  height: 100vh;
  position: sticky;
  flex: 0 0 auto;
  left: 0;
  top: 0;
  z-index: 14;
  overflow-y: auto;
  overflow-x: hidden;
`

export interface GetBuyerNavItemsArgs {
  analyticsFirstTab: string | undefined
  buyerCompany: BuyerCompanyAsBuyer
  canUseMapping?: boolean
  isMobile?: boolean
  marketplaceId: string
  nbPurchaseUnmapped: number
  optionFlagCanUseOrders?: boolean
  pathname: string
  unreadChatMessagesCount: number
  url: string
}

export const getBuyerNavItems = ({
  analyticsFirstTab,
  buyerCompany,
  canUseMapping,
  isMobile,
  marketplaceId,
  nbPurchaseUnmapped,
  optionFlagCanUseOrders,
  pathname,
  unreadChatMessagesCount,
  url,
}: GetBuyerNavItemsArgs) =>
  compact<NavItem>([
    {
      'data-e2e': 'market-nav-item',
      icon: 'basket-shopping',
      isActive: pathname.includes('/market/'),
      title: gettext('Market'),
      to: `${[url, 'marketplace', marketplaceId, 'market'].join('/')}/`,
    },
    optionFlagCanUseOrders && {
      'data-e2e': 'orders-nav-item',
      icon: 'truck-fast',
      title: gettext('Orders'),
      to: `${[url, 'marketplace', marketplaceId, 'orders'].join('/')}/`,
    },
    !isMobile && {
      'data-e2e': 'invoicing-nav-item',
      icon: 'receipt',
      isActive: pathname.includes('/invoicing/'),
      title: gettext('Invoices'),
      to: `${[url, 'marketplace', marketplaceId, 'invoicing'].join('/')}/`,
    },
    buyerCompany.canUseChat && {
      'data-e2e': 'chat-nav-item',
      icon: 'comments',
      notificationCount: unreadChatMessagesCount,
      title: gettext('Chat'),
      to: `${[url, 'marketplace', marketplaceId, 'chat'].join('/')}/`,
    },
    !isMobile &&
      analyticsFirstTab && {
        'data-e2e': 'analytics-nav-item',
        icon: 'chart-column',
        isActive: pathname.includes('/analytics/'),
        title: gettext('Analytics'),
        to: `${[url, 'marketplace', marketplaceId, 'analytics', analyticsFirstTab].join('/')}/`,
      },
    canUseMapping && {
      'data-e2e': 'mapping-item',
      icon: 'sliders',
      isActive: pathname.includes(`/mapping/`),
      notificationCount: nbPurchaseUnmapped,
      title: gettext('Mapping'),
      to: `${[url, 'marketplace', marketplaceId, 'mapping/purchases'].join('/')}/`,
    },
  ])

export interface BuyerNavigationProps {
  buyerCompany: BuyerCompanyAsBuyer
  currentMarketplaceId: Id
  unreadChatMessagesCount: number
  user: User
}

const BuyerNavigation = ({
  buyerCompany,
  currentMarketplaceId,
  unreadChatMessagesCount,
  user,
}: BuyerNavigationProps) => {
  const { pathname } = useLocation()
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { url } = useRouteMatch()
  const { optionFlagCanUseMapping } = useOptionFlags()

  const currentBuyerCompany = useCurrentBuyerCompany()
  const currentMarketplace = useCurrentMarketplace(currentMarketplaceId)

  const { data: dashboardPages } = usePaginatedDashboardsQuery({ queryParams: { limit: 1 } })
  const analyticsFirstTab = useMemo(
    () => head(head(dashboardPages?.pages)?.results)?.tabSlug,
    [JSON.stringify(dashboardPages)]
  )

  const navItems = getBuyerNavItems({
    analyticsFirstTab,
    buyerCompany,
    canUseMapping: optionFlagCanUseMapping,
    isMobile,
    marketplaceId: currentMarketplaceId.toString(),
    nbPurchaseUnmapped: user.nbPurchaseUnmapped,
    optionFlagCanUseOrders: currentBuyerCompany?.optionFlagDisplayOrdersTab,
    pathname,
    unreadChatMessagesCount,
    url,
  })

  return user.releaseToggleNewMenu ? (
    <MainMenu />
  ) : isMobile ? (
    <>
      <BuyerMobileHeader
        logoSrc={(currentMarketplace && currentMarketplace.mobileLogo) || undefined}
      />
      <TabBar
        items={navItems}
        accountTabBarItem={
          <BuyerAccountMenuItem
            analyticsFirstTab={analyticsFirstTab}
            buyerCompany={buyerCompany}
            marketplaceId={currentMarketplaceId}
            user={user}
          />
        }
      />
    </>
  ) : (
    <StyledSideBar
      data-e2e="side-bar"
      header={
        <img
          alt="marketplace-logo"
          className={classNames('marketplace-logo', { 'is-tablet': isTablet })}
          src={(currentMarketplace && currentMarketplace.desktopLogoLegacy) || undefined}
        />
      }
      navItems={navItems}
      commandMenu={
        <>
          <BuyerAccountMenuItem
            buyerCompany={buyerCompany}
            compact={isTablet}
            key="account-menu-item"
            marketplaceId={currentMarketplaceId}
            user={user}
          />
          {isDesktop && (
            <UserInfo
              companyId={buyerCompany.id}
              companyName={buyerCompany.name}
              fullName={user.fullName}
            />
          )}
          <AppEnvironment isTablet={isTablet} />
        </>
      }
      footer={<HelpMenu />}
      compact={isTablet}
    />
  )
}

export default BuyerNavigation
