import React from 'react'
import { Menu } from 'semantic-ui-react'

import { useBreakpoints } from '@procsea/design-system'

import { useUser } from 'src/contexts/userContext'

export interface BuyerAccountInformationHeaderProps {
  companyName?: string
}

const BuyerAccountInformationHeader = ({ companyName }: BuyerAccountInformationHeaderProps) => {
  const user = useUser()
  const { isDesktop } = useBreakpoints()

  return (
    <Menu.Item header>
      <div className="account-information-user-name" data-e2e="account-information-user-name">
        {user?.fullName}
      </div>

      {(user?.impersonableUsers.length === 0 || isDesktop) && (
        <div
          className="account-information-company-name"
          data-e2e="account-information-company-name"
        >
          {companyName}
        </div>
      )}

      {!!user?.isImpersonate && (
        <div
          className="account-information-phone-numbers color-lightRed"
          data-e2e="account-information-phone-numbers"
        >
          {user?.phone} - {user?.phoneMobile}
        </div>
      )}
    </Menu.Item>
  )
}

export default BuyerAccountInformationHeader
