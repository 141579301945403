import React, { memo } from 'react'

import './BuyerMobileHeader.css'

export interface BuyerMobileHeaderProps {
  logoSrc?: string
}

const BuyerMobileHeader = ({ logoSrc }: BuyerMobileHeaderProps) => (
  <header className="procsea-header">
    <img alt="marketplace_logo" className="marketplace-logo" src={logoSrc} />
  </header>
)

export default memo(BuyerMobileHeader)
